// Generated by Framer (e1a43d5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const serializationHash = "framer-oEJ03"

const variantClassNames = {xwjxKOTNr: "framer-v-1adnd24"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({finalRoundAI, height, id, width, ...props}) => { return {...props, dOIeZKsBf: finalRoundAI ?? props.dOIeZKsBf ?? {alt: "Final Round AI", src: "https://framerusercontent.com/images/CUPbiGn5d5BuP1J3l1gkP7ILfTs.jpg?scale-down-to=512", srcSet: "https://framerusercontent.com/images/CUPbiGn5d5BuP1J3l1gkP7ILfTs.jpg?scale-down-to=512 512w,https://framerusercontent.com/images/CUPbiGn5d5BuP1J3l1gkP7ILfTs.jpg 600w"}} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;finalRoundAI?: {src: string; srcSet?: string; alt?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, dOIeZKsBf, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "xwjxKOTNr", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fit", intrinsicHeight: 206.5, intrinsicWidth: 300, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 413, pixelWidth: 600, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(dOIeZKsBf), ...{ positionX: "center", positionY: "center" }}} className={cx(scopingClassNames, "framer-1adnd24", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"xwjxKOTNr"} ref={refBinding} style={{...style}}/></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-oEJ03.framer-1gsu8r4, .framer-oEJ03 .framer-1gsu8r4 { display: block; }", ".framer-oEJ03.framer-1adnd24 { height: 207px; overflow: visible; position: relative; width: 300px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 206.5
 * @framerIntrinsicWidth 300
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"dOIeZKsBf":"finalRoundAI"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerNl0xL7uhZ: React.ComponentType<Props> = withCSS(Component, css, "framer-oEJ03") as typeof Component;
export default FramerNl0xL7uhZ;

FramerNl0xL7uhZ.displayName = "Image";

FramerNl0xL7uhZ.defaultProps = {height: 206.5, width: 300};

addPropertyControls(FramerNl0xL7uhZ, {dOIeZKsBf: {__defaultAssetReference: "data:framer/asset-reference,CUPbiGn5d5BuP1J3l1gkP7ILfTs.jpg?originalFilename=Final_Round_AI.jpg&preferredSize=auto", __vekterDefault: {alt: "Final Round AI", assetReference: "data:framer/asset-reference,CUPbiGn5d5BuP1J3l1gkP7ILfTs.jpg?originalFilename=Final_Round_AI.jpg&preferredSize=auto"}, title: "Final Round AI", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerNl0xL7uhZ, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})